/**
* Send logs to ClickHouse. logs can be a single log, but usually it's an array of logs
*/
export async function sendLogsToIngestEndpoint(logs: any): Promise<[string, number]> {
  // IMPORTANT! Don't log anything here! It will create an infinite loop
  const dogfoodApiKey = process.env.IDLOG_DOGFOOD_API_KEY;
  if (!dogfoodApiKey) {
    throw 'dogfoodApiKey is not set';
  }

  // DEBUG: To debug local ObCollector, change to localhost
  // ------------------------------------------------------
  const baseUrl = 'https://ingest.obics.io'; // 'http://localhost:5183';
  const fullUrl = (baseUrl + '/api/v1/ingest').replace('//api', '/api');
  console.log('sendLogsToIngestEndpoint: endpoint=', fullUrl);
  const response = await fetch(fullUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': dogfoodApiKey
    },
    body: JSON.stringify(logs)
  });


  const responseText = await response.text();
  const responseStatus = response.status;
  return [responseText, responseStatus]
}