import "./src/global.css";
import { loggerForGatsby } from "./src/api-utils/logger"
// eslint-disable-next-line no-undef
require('./src/fontawesome');


export const onRouteUpdate = ({ location, prevLocation }) => {
  const logger = loggerForGatsby();  
  logger.info({now: location.pathname, prev: prevLocation?.pathname?? ""}, "route1");
};

// export { wrapRootElement } from './src/components/mdxProvider';