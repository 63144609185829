'use strict';

import { sendLogsToIngestEndpoint } from '../api-utils/ingest-utils'; 

export enum LogLevel {
  Debug = 1,
  Verbose = 2,
  Info = 3,
  Warn = 4,
  Error = 5,
  Critical = 6,

}

const LOG_INTERVAL_MS = 1000;

/**
 * Logger for client-side gatsby application
 */
export default class Logger {

  minLevel: LogLevel;
  host: string;
  service: string;
  isClientSide: boolean;
  scopeSessionId: string = '';

  private logQueue = [];

  public constructor(minLevel: LogLevel, service: string) {
    this.minLevel = minLevel;
    this.service = service;
    this.isClientSide = typeof window !== 'undefined';
    if (this.isClientSide) {
      this.host = window.location.hostname;
    } else { // for vercel serverless 
      const isLocal =  process.env.IDLOG_API_BASEURL?.startsWith('http://localhost');
      this.host = isLocal ? 'localhost' : 'vercel-serverless';
    }
    
    setTimeout(() => {
      this.logBatch();
    }, LOG_INTERVAL_MS);
  }

  public startScope(sessionId: string) {
    this.scopeSessionId = sessionId || '';
  }

  public endScope() {
    this.scopeSessionId = '';
  }

  public debug(message: string, logId: string): void {
    this.log(LogLevel.Debug, message, logId);
  }

  public verbose(message: string, logId: string): void {
    console.log(`[${this.levelToFriendlyString(LogLevel.Verbose)}] ${logId}`, message);
    this.log(LogLevel.Verbose, message, logId);
  }

  public info(message: string, logId: string): void {
    this.log(LogLevel.Info, message, logId);
  }

  public error(message: string, logId: string): void {
    this.log(LogLevel.Error, message, logId);
  }

  public warn(message: string, logId: string): void {
    this.log(LogLevel.Warn, message, logId);
  }



  private logBatch() {

    if (this.logQueue.length === 0) {
      setTimeout(() => {
        this.logBatch();
      }, LOG_INTERVAL_MS);
      return;
    }

    const logQueueClone = [...this.logQueue];
    // console.log('logQueueClone:', logQueueClone);

    this.logQueue = [];
    if (typeof window !== 'undefined') {
      this.sendLogsToServer(logQueueClone);
    } else {
      sendLogsToIngestEndpoint(logQueueClone).then((response) => {

        // console.log('Insert success:', response);
      }).catch((error) => {
        console.error('Error inserting data:', error?.toString());
      }).finally(() => {
        console.log('Finished request');
        setTimeout(() => {
          this.logBatch();
        }, LOG_INTERVAL_MS);
      });
    }
  }

  /**
   * Relevant to gatsby only
   */
  private sendLogsToServer(logs: any) {
    const base = window?.location?.hostname === 'localhost' ? 'http://localhost:8000' : 'https://obics.io';
    const url = base + '/api/logs-proxy';
    try {
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-session-id': this.getSessionId(),
        },
        body: JSON.stringify(logs),
      }).then(response => {
        console.log('Logs sent successfully. response:', response);
      }).catch(error => {
        console.error('Failed to send logs:', error);
      });
    } catch (error) {
      console.error('Failed to send logs:', error);
    }
  }


  private levelToFriendlyString(level: LogLevel): string {
    switch (level) {
      case LogLevel.Debug:
        return 'DEBUG';
      case LogLevel.Verbose:
        return 'VERBOSE';
      case LogLevel.Info:
        return 'INFO';
      case LogLevel.Warn:
        return 'WARN';
      case LogLevel.Error:
        return 'ERROR';
      case LogLevel.Critical:
        return 'CRITICAL';
      default:
        return 'UNKNOWN';
    }
  }

  /**
   * 
   * @returns Relevant to gatsby only
   */
  private getSessionId() {
    let session = sessionStorage.getItem('sessionId');
    if (session) {
      return session;
    }
    session = this.generateSessionID();
    sessionStorage.setItem('sessionId', session);
    return session;
  }

  private generateSessionID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  private log(level: number, message: string, logId: string): void {
    console.log(`[${this.levelToFriendlyString(level)} ${logId}] `, message);
    if (level < this.minLevel) {
      return;
    }
    const time = new Date().getTime();
    let sessionId: string = '';    
    if (this.isClientSide) {
      sessionId = this.getSessionId();
    } else {
      sessionId = this.scopeSessionId;
    }

    // check if message is object
    if (typeof message === 'object') {
      message = JSON.stringify(message);
    }

    this.logQueue.push({ time, logId, level, message, sessionId: sessionId, host: this.host, service: this.service });
  }

  

  
}

let loggerForAppAPIInstance: Logger | undefined = undefined;
let loggerForGatsbyInstance: Logger | undefined = undefined;

export const loggerForAppAPI = () => {
  if (!loggerForAppAPIInstance){
    loggerForAppAPIInstance = new Logger(LogLevel.Info, 'vercel-api');
  }
  return loggerForAppAPIInstance;
}

export const loggerForGatsby = () => {
  if (!loggerForGatsbyInstance){
    loggerForGatsbyInstance = new Logger(LogLevel.Info, 'gatsby');
  }
  return loggerForGatsbyInstance;
}
